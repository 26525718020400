<template>
    <div>
        <v-card outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <validation-provider
                                            v-slot="{ errors }"
                                            :name="$t('oampublication/label--place-text')"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="placeId"
                                                :items="userPlaces"
                                                item-text="text"
                                                item-value="id"
                                                :label="$t('oampublication/label--place-text')"
                                                :hint="$t('oampublication/label--place-text-hint')"
                                                :error-messages="errors"
                                                required
                                                persistent-hint
                                                :disabled="isActive"
                                                @change="changePlace"
                                            ></v-select>
                                        </validation-provider>
                                        <v-toolbar flat dense class="places-toolbar">
                                            <v-spacer />
                                            <v-btn right outlined color="primary" @click="showDialog()">
                                                {{ $t('oampublication/label--manage-places') }}
                                            </v-btn>
                                        </v-toolbar>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                                    <template #activator="{ on, attrs }">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="publishedFrom"
                                            rules="required|lt:@publishedTo"
                                            :name="$t('oampublication/label--published-from')"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                :value="compPublishedFrom"
                                                :label="$t('oampublication/label--published-from')"
                                                clearable
                                                v-bind="attrs"
                                                readonly
                                                v-on="on"
                                                @click:clear="publishedFrom = null"
                                            ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker
                                        v-model="publishedFrom"
                                        no-title
                                        color="primary"
                                        @input="dateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu v-model="dateMenu2" :close-on-content-click="false" max-width="290">
                                    <template #activator="{ on, attrs }">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="publishedTo"
                                            rules="gt:@publishedFrom"
                                        >
                                            <v-text-field
                                                :error-messages="errors"
                                                :value="compPublishedTo"
                                                :label="$t('oampublication/label--published-to')"
                                                clearable
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                @click:clear="publishedTo = null"
                                            ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker
                                        v-model="publishedTo"
                                        no-title
                                        color="primary"
                                        @input="dateMenu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="text--secondary caption">
                    {{ $t('oampublication/label--created-at') }} {{ compCreatedAt }}
                    {{ $t('oampublication/label--created-by') }} {{ createdBy }}
                </v-col>
            </v-card-actions>
        </v-card>
        <JobAdPubLocationManager v-if="dialog" name="LocationManager" :dialog="dialog" @show-dialog="showDialog()" />
    </div>
</template>

<script>
import { router } from '@/router'
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        dateMenu: false,
        dateMenu2: false,
        dialog: false,
    }),
    computed: {
        ...mapGetters('place', ['userPlaces']),
        ...mapFields('pub', [
            'currentItem.id',
            'currentItem.statusActive',
            'currentItem.statusText',
            'currentItem.placeId',
            'currentItem.publishedFrom',
            'currentItem.publishedTo',
            'currentItem.remarksToPublisher',
            'currentItem.isActive',
            'currentItem.link',
            'currentItem.applyLink',
            'currentItem.createdBy',
            'currentItem.createdAt',
        ]),
        compCreatedAt() {
            return this.createdAt ? moment(this.createdAt).format('DD.MM.YYYY HH:mm') : ''
        },
        compPublishedFrom() {
            return this.publishedFrom ? moment(this.publishedFrom).format('DD.MM.YYYY') : ''
        },
        compPublishedTo() {
            return this.publishedTo ? moment(this.publishedTo).format('DD.MM.YYYY') : ''
        },
    },
    mounted() {
        this.getPlaces(this.$route.params.pub).then(() => {
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('place', { getPlaces: 'getUserItems' }),
        ...mapActions('alert', ['showSuccess']),

        async durationChanged(duration) {
            this.publishedFrom = moment().toISOString()

            if (duration !== -1) {
                this.publishedTo = moment(this.publishedFrom)
                    .add(duration - 1, 'days')
                    .toISOString()
            } else {
                this.publishedTo = null
            }
        },

        close() {
            this.resetItem()
            router.push({ name: 'ad' })
        },

        save() {
            this.editItem()
            router.push({ name: 'ad' })
        },

        selectLink(event) {
            event.target.select()
        },

        showDialog() {
            this.dialog = !this.dialog
            this.getPlaces(this.$route.params.pub)
        },

        changePlace() {
            switch (parseInt(this.placeId)) {
                case -35:
                    if (moment().day() === 5 || moment().day() === 6) {
                        // friday or saturday -> show monday
                        this.publishedFrom = moment().day(8).toISOString()
                    } else {
                        // other days, show next day
                        this.publishedFrom = moment().add(1, 'days').toISOString()
                    }
                    this.publishedTo = moment(this.publishedFrom).add(24, 'days').toDate().toISOString()
                    break
                case -27:
                    this.publishedFrom = moment().toISOString()
                    this.publishedTo = moment(this.publishedFrom).add(59, 'days').toISOString()
                    break
                case -1000:
                    this.publishedFrom = moment().toISOString()
                    this.publishedTo = null
                    break
                default:
                    this.publishedFrom = moment().toISOString()
                    this.publishedTo = moment(this.publishedFrom).add(29, 'days').toISOString()
            }
        },
    },
}
</script>

<style scoped>
.places-toolbar >>> .v-toolbar__content {
    padding: 0px !important;
}
</style>
